<template>
  <el-dialog
    :title="'风险点'"
    :visible.sync="dialogVisible"
    custom-class="add-parts-retrieval-dialog"
    width="640px" append-to-body
    :before-close="handleClose"
  >
    <div>
      
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="风险类型" prop="deviceType">
          <el-select style="width:268px;" filterable disabled v-model="ruleForm.deviceType" placeholder="请选择" @change="selectMachineryProductType">
            <el-option v-for="item in riskTypeList"
                    :key="item.dictType"
                    :label="item.dictName"
                    :value="item.dictType"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理备注" prop="remark">
          <el-input
            type="textarea"
            v-model="ruleForm.remark"
            rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleReset">重 置</el-button>
      <el-button type="primary" @click="handleSubmit">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { riskHandle } from "@/api/SystemData";
export default {
  props: {
    showDialog: Boolean,
    equipmentData: {
      type: Object,
      default: ()=> {}
    },
    equipmentType: {
      type: [String,Number],
      default: ''
    },
    conditionObj: {
      type: Object,
      default: ()=> {}
    },
    alarmData: {
      type: Object,
      default: ()=> {}
    },
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }else {
          this.$emit("change", true);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.init();
          this.handleReset();
        }else {
          this.dialogVisible = false;
        }
      },
    },
    equipmentType: {
      handler(e) {
        this.ruleForm.deviceType = e;
      },
      deep: true,
    }
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        deviceType: "",
        remark: ''
      },
      rules: {
        remark: [
          { required: true, message: "请输入备注", trigger: "change" },
        ],
        deviceType: [
          { required: true, message: "请选择风险类型", trigger: "change" },
        ],
      },
      isPositionDialog: false,
      addressValue: "",
      suppliersIndex: '',
      deviceBrandgetData: [],
      riskTypeList: [{
        dictName: '设备离线',
        dictType: '1',
      },{
        dictName: '设备故障',
        dictType: '2',
      },{
        dictName: '天气监控',
        dictType: '3',
      },{
        dictName: '工时监控',
        dictType: '4',
      },{
        dictName: '保单脱保',
        dictType: '5',
      },{
        dictName: '设备出险',
        dictType: '6',
      },{
        dictName: '驾驶行为',
        dictType: '7',
      },{
        dictName: '视频监控',
        dictType: '8',
      },{
        dictName: '报警监控',
        dictType: '9',
      }],
    };
  },
  methods: {
    init() {
      // getDictList({ type: '1' }).then(res => {
      //   if (res.flag === 1) {
      //     this.machineryProductTypeList = res.rows;
      //   }
      // }).catch(err => {
      //   console.log(err);
      // });
    },
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
          done();
        // })
        // .catch((_) => {});
    },
    handleSubmit() {
      const that = this;
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
                riskHandle(
                  {
                    "vehicleId": that.equipmentData.vehicleId, //车辆id
                    "plate": that.equipmentData.plate, //车牌
                    "dataType": that.ruleForm.deviceType, //数据类型(1:设备离线，2:设备故障，3:天气监控，4:工时监控，5:保单脱保，6：设备出险)
                    "interveneWay": "风险点", //处理方式(确认无风险、误报，风险点)
                    "alarmId": that.alarmData?.id || '', //告警id
                    "alarmType": that.alarmData?.alarmType || '', //告警类型
                    "alarmTime": that.alarmData?.timeBegin || '', //告警时间
                    "remark": that.ruleForm.remark, //处理内容
                    "createUserId": that.$store.state.userInfo.userId, //处理用户id
                    "createUser": that.$store.state.userInfo.name, //处理用户名
                    "projectName": that.conditionObj.projectName, //项目名称
                    "projectIds": that.conditionObj.projectId, //项目id
                    "customName": that.conditionObj.customName //客户名称
                    
                    }
                ).then((res) => {
                  this.$message.success('处理成功');
                  this.$emit('changeList');
                  this.dialogVisible = false;
                }).catch((err) => {
                  this.$message.success('处理失败');
                });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleReset() {
        this.ruleForm = {
          deviceType: this.equipmentType || '',
          remark: ''
        }
    },
    selectMachineryProductType(e) {
      console.log(e);
    }
  },
};
</script>

<style lang="scss">
.add-parts-retrieval-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    // background: #4278c9;
    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    &-img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
      }
    }
  }
  .append-column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    font-size: 14px;
    color: #4278C9;
    cursor: pointer;
    border: 1px solid #DEE3E6;
    border-top: none;
    margin-top: -12px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .address-button {
    font-size: 14px;
    color: #3470FF;
    text-decoration:underline;
    cursor: pointer;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    width: 130px;
    height: 130px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 130px;
    height: 130px;
    line-height: 130px;
    text-align: center;
  }
  .avatar {
    width: 130px;
    height: 130px;
    display: block;
  }
}
</style>