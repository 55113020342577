<template>
  <SeamlessScroll
    ref="scrollRef"
    class="TextScrollWrapper"
    :data="splitData"
    :classOption="{direction:direction}"
  >
    <div class="scrollContent">
      <label
        :class="['scrollLabel', isLeft && 'directionLeft', isRight && 'directionRight']"
        v-for="(t, index) in splitData"
        :key="index"
      >{{t}}</label>
      <label :style="spaceStyle"></label>
    </div>
  </SeamlessScroll>
</template>

<script>

/**
 * 文本滚动组件
 */

// https://chenxuan0000.github.io/vue-seamless-scroll/guide/#npm
import SeamlessScroll from 'vue-seamless-scroll';

import { sleep } from '@/utils/sleep';

export default {
  name: 'TextScroll',
  components: {
    SeamlessScroll
  },
  props: {
    text: {
      type: String,
      default: '',
      required: true
    },
    // 方向: 2: 左; 3: 右
    direction: {
      type: Number,
      default: 2,
    }
  },
  computed: {
    splitData() {
      const { text } = this;
      return text.split('');
    },
    isLeft() {
      const { direction } = this;
      return direction === 2;
    },
    isRight() {
      const { direction } = this;
      return direction === 3;
    },
    /**
     * 通过文本长度计算空格宽度
     */
    spaceStyle() {
      const { splitData } = this;
      return {
        margin: `0 ${splitData.length * 12}px`
      };
    }
  },
  watch: {
    text(val) {
      if (!val) return;
      this.reset();
    }
  },

  mounted() {
    this.reset();
  },
  methods: {
    async reset() {
      const { scrollRef } = this.$refs;
      if (!scrollRef) return;
      await sleep(100);
      scrollRef.reset();
    }
  }
}
</script>

<style lang="scss" scoped>
.TextScrollWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.scrollContent {
  display: flex;
  flex-wrap: nowrap;
}
.scrollLabel {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
</style>