<template>
  <div class="AddressText" v-bind="$attrs">
    <!-- 是否滚动 -->
    <TextScroll v-if="autoScroll && isAddress" :text="innerAddress || '--'"></TextScroll>
    <template v-else>
      {{
      (isAddress ? innerAddress : lngLatLabel) || '--'
      }}
    </template>
  </div>
</template>
<script>

/**
 * 通过经纬度显示地址
 */


import { throttle } from 'lodash';
import TextScroll from '@/components/TextScroll';
import { getAddress } from '@/components/AMap/utils';

export default {
  components: {
    TextScroll,
  },
  model: {
    prop: 'address',
  },
  props: {
    // 地址
    address: {
      type: String,
      default: '',
    },
    /**
     * 经度(wgs84)
     */
    lng: {
      type: Number,
      default: null,
    },
    /**
     * 纬度(wgs84)
     */
    lat: {
      type: Number,
      default: null,
    },
    /**
     * true: 显示地址
     * fase: 显示经纬度
     */
    isAddress: {
      type: Boolean,
      default: true,
    },
    // 是否自动滚动
    autoScroll: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      innerAddress: '',
    };
  },
  computed: {
    lngLatLabel() {
      const { lnglat } = this;
      return lnglat.join(',');
    }
  },
  watch: {

    address(val) {
      this.innerAddress = val;
    },
    lng() {
      this.loadAddress();
    },
    lat() {
      this.loadAddress();
    }
  },
  created() {
    this.loadAddress = throttle(this.loadAddress, 300).bind(this);
  },
  beforeMount() {
    this.innerAddress = this.address;
    if (!this.address) {
      this.loadAddress();
    }
  },
  methods: {
    async loadAddress() {

      const { lng, lat } = this;

      if (!(lng && lat)) return this.innerAddress = '';

      const address = await getAddress(lng, lat);
      this.innerAddress = address;
      this.$emit('input', address);
    }
  }
}
</script>
<style lang="scss">
.AddressText {
}
</style>


